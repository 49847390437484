import moment from 'moment';
import { last, filter, toLower, get } from 'lodash';
import principalService from '../services/principalService';
import { appService } from '../services/appService';

export const signatureService = {
	initializeSignatureLib,
	loadSignaturePage,
	downloadAndSaveSignedDocument,
	closeSignature,
	retrieveActivityInfo,
	prepareSignatureFileForTransfer,
};

function initializeSignatureLib() {
	const script = document.createElement('script');

	script.src = '//vault.pactsafe.io/pactsafe-embed.js';
	script.async = false;

	document.body.appendChild(script);
}

function loadSignaturePage(merchantMPA, handleSignatureEvent) {
	let merchantEmail = principalService.get().emailFromToken;
	let requestedDocs = merchantMPA.signatureDoc.includedContracts;
	let contractList = [];
	requestedDocs.forEach(doc => {
		switch (doc) {
			case 'FDSignature':
				contractList.push(process.env.REACT_APP_SIGNATURE_FD_SIGNATURE_CONTRACT_ID);
				break;
			case 'FDConfirmation':
				contractList.push(process.env.REACT_APP_SIGNATURE_FD_CONFIRMATION_CONTRACT_ID);
				break;
			case 'NorthFacsSignature':
				contractList.push(process.env.REACT_APP_SIGNATURE_NORTH_FACS_CONTRACT_ID);
				break;
			case 'ElavonSignature':
				contractList.push(process.env.REACT_APP_SIGNATURE_ELAVON_SIGNATURE_CONTRACT_ID);
				break;
			case 'Reincorporation':
				contractList.push(process.env.REACT_APP_SIGNATURE_REINCORPORATION_CONTRACT_ID);
				break;
			case 'WirelessFeeElavon':
				contractList.push(process.env.REACT_APP_SIGNATURE_ELAVON_WIRELESS_FEE_ID);
				break;
			case 'BankInfoUpdate':
				contractList.push(process.env.REACT_APP_SIGNATURE_FD_BANK_INFORMATION_ID);
				break;
			case 'Reincorporation_BankInfo':
				contractList.push(process.env.REACT_APP_SIGNATURE_REINCORPORATION_BANK_INFORMATION_ID);
				break;
			case 'AmexOptBlue_PassThroughPricing':
				contractList.push(process.env.REACT_APP_SIGNATURE_AMEX_OPT_BLUE_PASS_THROUGH_PRICING_ID);
				break;
			case 'AmexOptBlue_TieredPricing':
				contractList.push(process.env.REACT_APP_SIGNATURE_AMEX_OPT_BLUE_TIERED_PRICING_ID);
				break;
			case 'AmexOptBlue_ErrPricing':
				contractList.push(process.env.REACT_APP_SIGNATURE_AMEX_OPT_BLUE_ERR_PRICING_ID);
				break;
			case 'AmexOptBlue_FlatRatePricing':
				contractList.push(process.env.REACT_APP_SIGNATURE_AMEX_OPT_FLAT_RATE_PRICING_ID);
				break;
			case 'PINDebit':
				contractList.push(process.env.REACT_APP_SIGNATURE_ADD_REMOVE_ENTITLEMENTS_PIN_DEBIT_ID);
				break;
			case 'AmexESA':
				contractList.push(process.env.REACT_APP_SIGNATURE_ADD_REMOVE_ENTITLEMENTS_AMEX_ID);
				break;
			case 'WirelessFeeFirstData':
				contractList.push(process.env.REACT_APP_SIGNATURE_ADD_REMOVE_WIRELESS_FEE_FD_ID);
				break;
			case 'EBT':
				contractList.push(process.env.REACT_APP_SIGNATURE_ADD_REMOVE_ENTITLEMENTS_EBT_ID);
				break;
			case 'WirelessFeeCardknoxGo':
				contractList.push(process.env.REACT_APP_SIGNATURE_CARDKNOX_GO_WIRELESS_FEE_ID);
				break;
			case 'WirelessFeeVantiv':
				contractList.push(process.env.REACT_APP_SIGNATURE_VANTIV_WIRELESS_FEE_ID);
				break;
			default:
				console.error('Unknown document type:', doc);
				break;
		}
	});
	let signerIdAsString = 'pp' + merchantMPA.appId + '';
	let renderDataJson;
	let renderFields;

	switch (contractList[0]) {
		case process.env.REACT_APP_SIGNATURE_MPA_CONTRACT_ID:
		case process.env.REACT_APP_SIGNATURE_FD_SIGNATURE_CONTRACT_ID:
		case process.env.REACT_APP_SIGNATURE_FD_CONFIRMATION_CONTRACT_ID:
		case process.env.REACT_APP_SIGNATURE_NORTH_FACS_CONTRACT_ID:
		case process.env.REACT_APP_SIGNATURE_ELAVON_SIGNATURE_CONTRACT_ID:
			renderDataJson = renderJsonData_MPA(merchantMPA);
			renderFields = renderFields_MPA(merchantMPA);
			break;
		case process.env.REACT_APP_SIGNATURE_REINCORPORATION_CONTRACT_ID:
			renderFields = renderFields_Reincorporation(merchantMPA);
			break;
		case process.env.REACT_APP_SIGNATURE_ELAVON_WIRELESS_FEE_ID:
			renderFields = renderFields_ElavonWirelessFee(merchantMPA);
			break;
		case process.env.REACT_APP_SIGNATURE_FD_BANK_INFORMATION_ID:
			renderFields = renderFields_BankInformation(merchantMPA);
			break;
		case process.env.REACT_APP_SIGNATURE_REINCORPORATION_BANK_INFORMATION_ID:
			renderFields = renderFields_ReincorporationBankInformation(merchantMPA);
			break;
		case process.env.REACT_APP_SIGNATURE_AMEX_OPT_BLUE_PASS_THROUGH_PRICING_ID:
			renderFields = renderFields_PassThroughPricing(merchantMPA);
			break;
		case process.env.REACT_APP_SIGNATURE_AMEX_OPT_BLUE_TIERED_PRICING_ID:
			renderFields = renderFields_TieredPricing(merchantMPA);
			break;
		case process.env.REACT_APP_SIGNATURE_AMEX_OPT_BLUE_ERR_PRICING_ID:
			renderFields = renderFields_ErrPricing(merchantMPA);
			break;
		case process.env.REACT_APP_SIGNATURE_AMEX_OPT_FLAT_RATE_PRICING_ID:
			renderFields = renderFields_FlatRatePricing(merchantMPA);
			break;
		case process.env.REACT_APP_SIGNATURE_ADD_REMOVE_ENTITLEMENTS_PIN_DEBIT_ID:
			renderFields = renderFields_AddRemoveEntitlementsPinDebit(merchantMPA);
			break;
		case process.env.REACT_APP_SIGNATURE_ADD_REMOVE_ENTITLEMENTS_AMEX_ID:
			renderFields = renderFields_AddRemoveEntitlementsAmex(merchantMPA);
			break;
		case process.env.REACT_APP_SIGNATURE_ADD_REMOVE_WIRELESS_FEE_FD_ID:
			renderFields = renderFields_AddRemoveWirelessFee(merchantMPA);
			break;
		case process.env.REACT_APP_SIGNATURE_ADD_REMOVE_ENTITLEMENTS_EBT_ID:
			renderFields = renderFields_AddRemoveEntitlementsEBT(merchantMPA);
			break;
		case process.env.REACT_APP_SIGNATURE_CARDKNOX_GO_WIRELESS_FEE_ID:
			renderFields = renderFields_WirelessFeeForm(merchantMPA);
			break;
		case process.env.REACT_APP_SIGNATURE_VANTIV_WIRELESS_FEE_ID:
			renderFields = renderFields_WirelessFeeForm(merchantMPA);
			break;
		default:
			console.error('Unknown contract ID:', contractList[0]);
			break;
	}

	let requestJson = {
		contracts: contractList,
		signers: [
			{
				signer_id: signerIdAsString,
				signer: {
					email: merchantEmail,
				},
				send_to: { email: false },
			},
		],
		fields: renderFields,
		render_data: renderDataJson,
		redirect_url: window.location.href.replace(window.location.pathname, '/merchant/processing').split('?')[0],
	};

	if (merchantMPA.signatureDoc.refId && merchantMPA.signatureDoc.refId !== 'n/a') {
		let requestId = merchantMPA.signatureDoc.refId;
		return appService
			.pactsafePauseRequest(merchantMPA.appId, requestId)
			.then(handleResponse)
			.catch(err => {
				console.log('pause error', err);
				if (err && err.error.message !== 'Request is already paused.') {
					throw err;
				}
			})
			.then(() => {
				return appService
					.pactsafeUpdateRequest(merchantMPA.appId, requestId, JSON.stringify({ render_data: renderDataJson }))
					.then(handleResponse)
					.catch(err => {
						console.error('request error', err);
						return Promise.resolve({ ...err, isPatchError: true });
					})
					.then(updateResponse => unpauseRequest(updateResponse, requestId, merchantMPA, handleSignatureEvent));
			});
	} else {
		return appService
			.pactsafeCreateRequest(merchantMPA.appId, JSON.stringify(requestJson))
			.then(handleResponse)
			.then(createResponse => {
				return appService
					.pactsafeSendRequest(merchantMPA.appId, createResponse.data.id)
					.then(handleResponse)
					.then(sendResponse => {
						console.log('response from send ' + sendResponse);
						return handleSignatureRequestResponse(createResponse, merchantMPA, handleSignatureEvent);
					});
			});
	}
}
function unpauseRequest(updateResponse, requestId, merchantMPA, handleSignatureEvent) {
	return appService
		.pactsafeUnpauseRequest(merchantMPA.appId, requestId)
		.then(handleResponse)
		.then(() => {
			console.log('finished unpausing');
			if (updateResponse.isPatchError) return Promise.reject(updateResponse);
			return handleSignatureRequestResponse(updateResponse, merchantMPA, handleSignatureEvent);
		});
}
function renderJsonData_MPA(merchantMPA) {
	return {
		corporate_name: merchantMPA.corporateName,
		business_phone: merchantMPA.businessInformation.businessPhone,
		business_start_date: moment(
			merchantMPA.businessStartDate,
			process.env.REACT_APP_API_RESPONSE_DATE_TIME_FORMAT
		).format(process.env.REACT_APP_DISPLAY_DATE_FORMAT),
		dba: merchantMPA.dba,
		business_email: merchantMPA.businessInformation.businessEmail,
		business_address: merchantMPA.businessInformation.businessAddress.streetAddress,
		business_city: merchantMPA.businessInformation.businessAddress.city,
		business_state: merchantMPA.businessInformation.businessAddress.state,
		business_zip: merchantMPA.businessInformation.businessAddress.zip,
		mailing_address: merchantMPA.corporateAddress.streetAddress,
		mailing_city: merchantMPA.corporateAddress.city,
		mailing_state: merchantMPA.corporateAddress.state,
		mailing_zip: merchantMPA.corporateAddress.zip,
		goods_services_description: merchantMPA.goodsOrServicesDescription,
		website: merchantMPA.businessInformation.website,
		tax_id: merchantMPA.taxID,
		business_fax: merchantMPA.businessInformation.businessFax,
		ownership_type: merchantMPA.businessInformation.ownershipType,
		bank_routing_number: merchantMPA.bankAccountInformation.routingNumber,
		bank_dda_number: merchantMPA.bankAccountInformation.accountNumber,
		bank_name: merchantMPA.bankAccountInformation.bankName,
		owner_first_name: merchantMPA.signerInformation.firstName,
		owner_last_name: merchantMPA.signerInformation.lastName,
		signer_address: merchantMPA.signerInformation.address.streetAddress,
		signer_state: merchantMPA.signerInformation.address.state,
		signer_city: merchantMPA.signerInformation.address.city,
		signer_zip: merchantMPA.signerInformation.address.zip,
		signer_phone: merchantMPA.signerInformation.phoneNumber,
		signer_ssn: merchantMPA.signerInformation.socialSecurityNumber,
		signer_cell: merchantMPA.signerInformation.cellPhoneNumber,
		signer_dob: moment(
			merchantMPA.signerInformation.dateOfBirth,
			process.env.REACT_APP_API_RESPONSE_DATE_TIME_FORMAT
		).format(process.env.REACT_APP_DISPLAY_DATE_FORMAT),
		amex_esa_number: get(merchantMPA, 'amexDetails.esaNumber', ''),
		accepts_ebt: merchantMPA.ebtNumber,
		title: merchantMPA.signerInformation.title,
	};
}
function renderFields_MPA(merchantMPA) {
	let signerIdAsString = 'pp' + merchantMPA.appId + '';

	return [
		{
			name: 'corporate_name',
			default_value: merchantMPA.corporateName,
			assigned_to: signerIdAsString,
		},
		{
			name: 'signer_name',
			default_value: merchantMPA.ownerName,
			assigned_to: signerIdAsString,
		},
		{
			name: 'dba',
			default_value: merchantMPA.dba,
			assigned_to: signerIdAsString,
		},
		{
			name: 'title',
			default_value: merchantMPA.signerInformation.title,
			assigned_to: signerIdAsString,
		},
		{
			name: 'signature',
			assigned_to: signerIdAsString,
		},
		{
			name: 'signed_date',
			assigned_to: signerIdAsString,
		},
		{
			assigned_to: signerIdAsString,
		},
	];
}
function renderFields_Reincorporation(merchantMPA) {
	let bsDocumentInfo = JSON.parse(merchantMPA.signatureDoc.documentInfo);
	let signerIdAsString = 'pp' + merchantMPA.appId + '';

	return [
		...renderFields_Signature(merchantMPA),
		{
			name: 'irs_name',
			default_value: bsDocumentInfo.IRS_Name,
			assigned_to: signerIdAsString,
		},
		{
			name: 'taxpayer_id',
			default_value: bsDocumentInfo.TaxpayerID,
			assigned_to: signerIdAsString,
		},
		{
			name: 'tin_type',
			default_value: bsDocumentInfo.TinType,
			assigned_to: signerIdAsString,
		},
		{
			name: 'tax_effective_year',
			default_value: bsDocumentInfo.TaxEffectiveYear,
			assigned_to: signerIdAsString,
		},
		{
			name: 'change_effective_date',
			default_value: bsDocumentInfo.ChangeEffectiveDate,
			assigned_to: signerIdAsString,
		},
		{
			name: 'ownershiptype_new',
			assigned_to: signerIdAsString,
		},
		{
			name: 'corporatename_current',
			default_value: merchantMPA.corporateName,
			assigned_to: signerIdAsString,
		},
		{
			name: 'corporatename_new',
			default_value: bsDocumentInfo.LegalBusinessName_New,
			assigned_to: signerIdAsString,
		},
		{
			name: 'ownershiptype_current',
			assigned_to: signerIdAsString,
		},
		{
			name: 'dba',
			default_value: merchantMPA.dba,
			assigned_to: signerIdAsString,
		},
		{
			name: 'ownername_current',
			default_value: merchantMPA.ownerName,
			assigned_to: signerIdAsString,
		},
		{
			name: 'ownername_new',
			default_value: merchantMPA.ownerName,
			assigned_to: signerIdAsString,
		},
		{
			name: 'ownertitle_current',
			default_value: merchantMPA.signerInformation.title,
			assigned_to: signerIdAsString,
		},
		{
			name: 'ownertitle_new',
			default_value: merchantMPA.signerInformation.title,
			assigned_to: signerIdAsString,
		},
		{
			name: 'owneraddress_current',
			default_value: merchantMPA.signerInformation.address.streetAddress,
			assigned_to: signerIdAsString,
		},
		{
			name: 'owneraddress_new',
			default_value: merchantMPA.signerInformation.address.streetAddress,
			assigned_to: signerIdAsString,
		},
		{
			name: 'ownerdob_current',
			default_value: merchantMPA.signerInformation.dateOfBirth,
			assigned_to: signerIdAsString,
		},
		{
			name: 'ownerdob_new',
			default_value: merchantMPA.signerInformation.dateOfBirth,
			assigned_to: signerIdAsString,
		},
		{
			name: 'ownerssn_current',
			default_value: merchantMPA.signerInformation.socialSecurityNumber,
			assigned_to: signerIdAsString,
		},
		{
			name: 'ownerssn_new',
			default_value: merchantMPA.signerInformation.socialSecurityNumber,
			assigned_to: signerIdAsString,
		},
	];
}
function renderFields_ElavonWirelessFee(merchantMPA) {
	let bsDocumentInfo = JSON.parse(merchantMPA.signatureDoc.documentInfo);
	let signerIdAsString = 'pp' + merchantMPA.appId + '';

	return [
		...renderFields_Signature(merchantMPA),
		{
			name: 'mid',
			default_value: merchantMPA.appId,
			assigned_to: signerIdAsString,
		},
		{
			name: 'msp_short_name',
			default_value: bsDocumentInfo.ContactName,
			assigned_to: signerIdAsString,
		},
		{
			name: 'msp_contact_name',
			default_value: bsDocumentInfo.ContactName,
			assigned_to: signerIdAsString,
		},
		{
			name: 'msp_email',
			default_value: bsDocumentInfo.ContactEmail,
			assigned_to: signerIdAsString,
		},
		{
			name: '6616_wrls_fee',
			default_value: bsDocumentInfo.WirelessFee,
			assigned_to: signerIdAsString,
		},
		{
			name: 'number_of_terminals',
			default_value: bsDocumentInfo.WirelessTerminalCount,
			assigned_to: signerIdAsString,
		},
		{
			name: 'reactivate',
			default_value: true,
			assigned_to: signerIdAsString,
		},
	];
}
function renderFields_BankInformation(merchantMPA) {
	let bsDocumentInfo = JSON.parse(merchantMPA.signatureDoc.documentInfo);
	let signerIdAsString = 'pp' + merchantMPA.appId + '';

	return [
		...renderFields_Signature(merchantMPA),
		{
			name: 'agent_name',
			default_value: bsDocumentInfo.agentName,
			assigned_to: signerIdAsString,
		},
		{
			name: 'agent_email',
			default_value: bsDocumentInfo.agentEmail,
			assigned_to: signerIdAsString,
		},
		{
			name: 'bank_name',
			default_value: bsDocumentInfo.BankName,
			assigned_to: signerIdAsString,
		},
		{
			name: 'routing_current',
			default_value: merchantMPA.bankAccountInformation.routingNumber,
			assigned_to: signerIdAsString,
		},
		{
			name: 'routing_new',
			default_value: bsDocumentInfo.ABA_New,
			assigned_to: signerIdAsString,
		},
		{
			name: 'account_current',
			default_value: merchantMPA.bankAccountInformation.accountNumber,
			assigned_to: signerIdAsString,
		},
		{
			name: 'account_new',
			default_value: bsDocumentInfo.DDA_New,
			assigned_to: signerIdAsString,
		},
		{
			name: 'bank_phone',
			default_value: bsDocumentInfo.bankPhone,
			assigned_to: signerIdAsString,
		},
		{
			name: 'merchant_name',
			default_value: bsDocumentInfo.MyMerchantSignatureName,
			assigned_to: signerIdAsString,
		},
		{
			name: 'merchant_title',
			default_value: merchantMPA.signerInformation.title,
			assigned_to: signerIdAsString,
		},
	];
}
function renderFields_ReincorporationBankInformation(merchantMPA) {
	return [...renderFields_BankInformation(merchantMPA), ...renderFields_Reincorporation(merchantMPA)];
}
function renderFields_PassThroughPricing(merchantMPA) {
	let bsDocumentInfo = JSON.parse(merchantMPA.signatureDoc.documentInfo);
	let signerIdAsString = 'pp' + merchantMPA.appId + '';

	return [
		...renderFields_Signature(merchantMPA),
		...renderFields_Amex(merchantMPA),
		{
			name: 'amex_opt_blue_credit',
			default_value: bsDocumentInfo.PassThru_DiscountRate,
			assigned_to: signerIdAsString,
		},
		...renderFields_Information(merchantMPA),
	];
}
function renderFields_TieredPricing(merchantMPA) {
	let bsDocumentInfo = JSON.parse(merchantMPA.signatureDoc.documentInfo);
	let signerIdAsString = 'pp' + merchantMPA.appId + '';

	return [
		...renderFields_Signature(merchantMPA),
		...renderFields_Amex(merchantMPA),
		{
			name: 'amex_opt_blue_tdg',
			default_value: bsDocumentInfo.OptBlue_TDG,
			assigned_to: signerIdAsString,
		},
		{
			name: 'amex_opt_blue_qualified_credit',
			default_value: bsDocumentInfo.Tiered_Qual_DiscountRate,
			assigned_to: signerIdAsString,
		},
		{
			name: 'amex_opt_blue_mid-qualified_credit',
			default_value: bsDocumentInfo.Tiered_MidQual_DiscountRate,
			assigned_to: signerIdAsString,
		},
		{
			name: 'amex_opt_blue_non-qualified_credit',
			default_value: bsDocumentInfo.Tiered_NonQual_DiscountRate,
			assigned_to: signerIdAsString,
		},
		{
			name: 'amex_opt_blue_qualified_credit_other_volume',
			default_value: bsDocumentInfo.Tiered_Qual_OtherVolumePerc,
			assigned_to: signerIdAsString,
		},
		{
			name: 'amex_opt_blue_mid-qualified_credit_other_volume',
			default_value: bsDocumentInfo.Tiered_MidQual_OtherVolumePerc,
			assigned_to: signerIdAsString,
		},
		{
			name: 'amex_opt_blue_non-qualified_credit_other_volume',
			default_value: bsDocumentInfo.Tiered_NonQual_OtherVolumePerc,
			assigned_to: signerIdAsString,
		},
		...renderFields_Information(merchantMPA),
	];
}
function renderFields_ErrPricing(merchantMPA) {
	let bsDocumentInfo = JSON.parse(merchantMPA.signatureDoc.documentInfo);
	let signerIdAsString = 'pp' + merchantMPA.appId + '';

	return [
		...renderFields_Signature(merchantMPA),
		...renderFields_Amex(merchantMPA),
		{
			name: 'amex_opt_blue_credit_discount_rate',
			default_value: bsDocumentInfo.ERR_DiscountRate,
			assigned_to: signerIdAsString,
		},
		{
			name: 'amex_opt_blue_credit_err',
			default_value: bsDocumentInfo.ERR_Perc,
			assigned_to: signerIdAsString,
		},
		...renderFields_Information(merchantMPA),
	];
}
function renderFields_FlatRatePricing(merchantMPA) {
	let bsDocumentInfo = JSON.parse(merchantMPA.signatureDoc.documentInfo);
	let signerIdAsString = 'pp' + merchantMPA.appId + '';

	return [
		...renderFields_Signature(merchantMPA),
		...renderFields_Amex(merchantMPA),
		{
			name: 'amex_opt_blue_credit_discount_rate',
			default_value: bsDocumentInfo.FlatRate_DiscountRate,
			assigned_to: signerIdAsString,
		},
		...renderFields_Information(merchantMPA),
	];
}
function renderFields_AddRemoveEntitlementsPinDebit(merchantMPA) {
	let bsDocumentInfo = JSON.parse(merchantMPA.signatureDoc.documentInfo);
	let signerIdAsString = 'pp' + merchantMPA.appId + '';

	return [
		...renderFields_Signature(merchantMPA),
		{
			name: 'other_item_rate',
			default_value: bsDocumentInfo.PINDebit_TransFee,
			assigned_to: signerIdAsString,
		},
		{
			name: 'other_volume_percentage',
			default_value: bsDocumentInfo.PINDebit_PercentMarkup,
			assigned_to: signerIdAsString,
		},
	];
}
function renderFields_AddRemoveEntitlementsAmex(merchantMPA) {
	let bsDocumentInfo = JSON.parse(merchantMPA.signatureDoc.documentInfo);
	let signerIdAsString = 'pp' + merchantMPA.appId + '';

	return [
		...renderFields_Signature(merchantMPA),
		{
			name: 'other_item_rate',
			default_value: bsDocumentInfo.Amex_OtherRate,
			assigned_to: signerIdAsString,
		},
		{
			name: 'amex_se',
			default_value: bsDocumentInfo.Amex_SE,
			assigned_to: signerIdAsString,
		},
	];
}
function renderFields_AddRemoveEntitlementsEBT(merchantMPA) {
	let bsDocumentInfo = JSON.parse(merchantMPA.signatureDoc.documentInfo);
	let signerIdAsString = 'pp' + merchantMPA.appId + '';

	return [
		...renderFields_Signature(merchantMPA),
		{
			name: 'ebt_food_stamps_other_item_rate',
			default_value: bsDocumentInfo.EBT_TransFee,
			assigned_to: signerIdAsString,
		},
		{
			name: 'ebt_cash_benefits_other_item_rate',
			default_value: bsDocumentInfo.EBT_TransFee,
			assigned_to: signerIdAsString,
		},
		{
			name: 'fns',
			default_value: bsDocumentInfo.EBT_FNS,
			assigned_to: signerIdAsString,
		},
	];
}
function renderFields_WirelessFeeForm(merchantMPA) {
	let signerIdAsString = 'pp' + merchantMPA.appId + '';
	return [
		...renderFields_Signature(merchantMPA),
		{
			name: 'mid',
			default_value: merchantMPA.appId,
			assigned_to: signerIdAsString,
		},
		{
			name: 'address',
			default_value: merchantMPA.businessInformation.businessAddress.streetAddress,
			assigned_to: signerIdAsString,
		},
		{
			name: 'city',
			default_value: merchantMPA.businessInformation.businessAddress.city,
			assigned_to: signerIdAsString,
		},
		{
			name: 'state',
			default_value: merchantMPA.businessInformation.businessAddress.state,
			assigned_to: signerIdAsString,
		},
		{
			name: 'zip_code',
			default_value: merchantMPA.businessInformation.businessAddress.zip,
			assigned_to: signerIdAsString,
		},
		{
			name: 'name',
			default_value: merchantMPA.businessContactName,
			assigned_to: signerIdAsString,
		},
		{
			name: 'email',
			default_value: merchantMPA.businessInformation.businessEmail,
			assigned_to: signerIdAsString,
		},
		{
			name: 'phone_number',
			default_value: merchantMPA.businessInformation.businessPhone,
			assigned_to: signerIdAsString,
		},
		{
			name: 'cell_phone',
			default_value: merchantMPA.signerInformation.cellPhoneNumber,
			assigned_to: signerIdAsString,
		},
	];
}
function renderFields_AddRemoveWirelessFee(merchantMPA) {
	let bsDocumentInfo = JSON.parse(merchantMPA.signatureDoc.documentInfo);
	let signerIdAsString = 'pp' + merchantMPA.appId + '';

	return [
		...renderFields_Signature(merchantMPA),
		{
			name: 'user_defined_income_grid',
			default_value: bsDocumentInfo.WirelessFee_UDGGrid,
			assigned_to: signerIdAsString,
		},
	];
}
function renderFields_Signature(merchantMPA) {
	let signerIdAsString = 'pp' + merchantMPA.appId + '';

	return [
		{
			name: 'signature',
			assigned_to: signerIdAsString,
		},
		{
			name: 'date',
			assigned_to: signerIdAsString,
		},
		{
			name: 'signed_date',
			assigned_to: signerIdAsString,
		},
		{
			name: 'dba',
			default_value: merchantMPA.dba,
			assigned_to: signerIdAsString,
		},
		{
			name: 'mid',
			default_value: merchantMPA.mid,
			assigned_to: signerIdAsString,
		},
	];
}
function renderFields_Information(merchantMPA) {
	let signerIdAsString = 'pp' + merchantMPA.appId + '';
	let firstName = merchantMPA.signerInformation.firstName;
	let lastName = merchantMPA.signerInformation.lastName;

	return [
		{
			name: 'first_name',
			default_value: firstName ? firstName : 'firstName',
			assigned_to: signerIdAsString,
		},
		{
			name: 'last_name',
			default_value: lastName ? lastName : 'lastName',
			assigned_to: signerIdAsString,
		},
		{
			name: 'address',
			default_value: merchantMPA.signerInformation.address.streetAddress,
			assigned_to: signerIdAsString,
		},
		{
			name: 'city',
			default_value: merchantMPA.signerInformation.address.city,
			assigned_to: signerIdAsString,
		},
		{
			name: 'state',
			default_value: merchantMPA.signerInformation.address.state,
			assigned_to: signerIdAsString,
		},
		{
			name: 'zip_code',
			default_value: merchantMPA.signerInformation.address.zip,
			assigned_to: signerIdAsString,
		},
		{
			name: 'ssn',
			default_value: merchantMPA.signerInformation.socialSecurityNumber,
			assigned_to: signerIdAsString,
		},
		{
			name: 'date_of_birth',
			default_value: merchantMPA.signerInformation.dateOfBirth,
			assigned_to: signerIdAsString,
		},
		{
			name: 'client',
			default_value: merchantMPA.dba,
			assigned_to: signerIdAsString,
		},
		{
			name: 'by',
			assigned_to: signerIdAsString,
		},
		{
			name: 'by_title',
			default_value: merchantMPA.signerInformation.title,
			assigned_to: signerIdAsString,
		},
		{
			name: 'by_date',
			assigned_to: signerIdAsString,
		},
	];
}
function renderFields_Amex(merchantMPA) {
	let bsDocumentInfo = JSON.parse(merchantMPA.signatureDoc.documentInfo);
	let signerIdAsString = 'pp' + merchantMPA.appId + '';

	return [
		{
			name: 'omaha_only',
			default_value: true,
			assigned_to: signerIdAsString,
		},
		{
			name: 'amex_opt_blue_fee_class',
			default_value: bsDocumentInfo.feeClass,
			assigned_to: signerIdAsString,
		},
	];
}

function handleSignatureRequestResponse(createOrUpdateResponse, merchantMPA, handleSignatureEvent) {
	let respData = {
		request_url: createOrUpdateResponse.data.signers[0].request_url,
		download_request_url: createOrUpdateResponse.data.signers[0].download_request_url,
		request_id: createOrUpdateResponse.data.id,
	};
	loadEmbeddedSignature(
		respData.request_url,
		handleSignatureEvent(merchantMPA, respData.request_id, respData.download_request_url)
	);
	return respData;
}

let EmbeddedSignature;
function loadEmbeddedSignature(url, eventListener) {
	EmbeddedSignature = new window.PactSafeEmbedded(process.env.REACT_APP_SIGNATURE_CLIENT_ID);

	EmbeddedSignature.open({
		url: url,
		allow_cancel: true,
		debug: true,
		from_left: 0,
		from_top: 0,
		message_listener: eventListener,
	});
}

function closeSignature() {
	EmbeddedSignature && EmbeddedSignature.close();
}

function retrieveActivityInfoPage(page, appId) {
	return appService
		.pactsafeRetrieveActivityInfo(appId, page)
		.then(handleResponse)
		.then(response => {
			if (!response.has_more) {
				const lastAgreedEvent = last(filter(response.data, ({ event_type }) => toLower(event_type) === 'agreed'));
				if (lastAgreedEvent) {
					return lastAgreedEvent.id;
				}
			} else {
				return retrieveActivityInfoPage(page - 1, appId);
			}
		});
}

function retrieveActivityInfo(appId) {
	return appService
		.pactsafeRetrieveActivityInfo(appId)
		.then(handleResponse)
		.then(resp => {
			if (!resp.has_more) {
				const lastAgreedEvent = last(filter(resp.data, ({ event_type }) => toLower(event_type) === 'agreed'));
				if (lastAgreedEvent) {
					return lastAgreedEvent.id;
				}
			} else {
				return retrieveActivityInfoPage(Math.ceil(resp.total_count / resp.per_page), appId);
			}
		})
		.then(activityId => {
			if (activityId) {
				return appService
					.pactsafeRetrieveActivity(appId, activityId)
					.then(handleResponse)
					.then(resp => {
						return resp;
					});
			}
			return null;
		});
}

function prepareSignatureFileForTransfer(mpa, includedContracts, downloadUrl) {
	console.log('prepareSignatureFileForTransfer enter');
	const requestOptions = {
		method: 'GET',
	};

	return fetch(new Request(downloadUrl, requestOptions))
		.then(response => {
			if (response.ok) {
				let timestamp = moment().format('YYYYMMDDHHmmss');
				let documentName = 'SignedApp';
				return response.blob().then(blob => {
					return new File([blob], documentName + '_' + mpa.appId + '_' + timestamp + '.pdf');
				});
			} else {
				console.log('failed download from ps url');
				console.log(response);
				return;
			}
		})
		.then(body => {
			if (body) {
				let fileTag = GetFileTag(includedContracts);
				return appService.prepareMerchantFileForTransfer(mpa.appId, body, fileTag);
			} else {
				return Promise.reject('Error downloading file. Please check PactSafe for the signed document.');
			}
		});
}

function downloadAndSaveSignedDocument(mpa, includedContracts, downloadUrl, ticketId, isGo, additionalAppIds = []) {
	console.log('downloadAndSaveSignedDocument enter');
	const requestOptions = {
		method: 'GET',
	};

	const resp = fetch(new Request(downloadUrl, requestOptions))
		.then(response => {
			if (response.ok) {
				let timestamp = moment().format('YYYYMMDDHHmmss');
				let documentName = ticketId ? includedContracts[0] : 'SignedApp';
				return response.blob().then(blob => {
					return new File([blob], documentName + '_' + mpa.appId + '_' + timestamp + '.pdf');
				});
			} else {
				console.log('failed download from ps url');
				console.log(response);
				return;
			}
		})
		.then(body => {
			if (body) {
				const fileList = {};
				let fileTag = GetFileTag(includedContracts);
				fileList[fileTag] = [{ file: body, fileDescription: '' }];
				if (ticketId) {
					appService.updateBankingServicesRequest(mpa.appId, ticketId, fileList);
				} else {
					return saveMerchantFiles(mpa, fileList, isGo, additionalAppIds);
				}
			} else {
				return Promise.reject('Error downloading file. Please check PactSafe for the signed document.');
			}
		});
	return resp;
}

function GetFileTag(includedContracts) {
	let fileTag = '';
	if (includedContracts.includes('ClickwrapMPA')) fileTag = 'SignedMPA';
	if (includedContracts.includes('FDSignature')) fileTag = 'FDSignature';
	if (includedContracts.filter((d, i) => d !== 'ClickwrapMPA').length > 0) {
		if (fileTag.length > 0) fileTag = fileTag + '_';
		fileTag = fileTag + 'SignaturePages';
	}
	return fileTag;
}

async function saveMerchantFiles(mpa, fileList, isGo, additionalAppIds) {
	await appService.saveMerchantFiles(mpa.appId, fileList, mpa, isGo, additionalAppIds);
}

function handleResponse(data) {
	return new Promise((resolve, reject) => {
		try {
			const text = data && JSON.parse(data['text']); //All Pactsafe responses are returned in 'Text' property
			return resolve(text);
		} catch (e) {
			reject(e);
		}
	});
}
